<template>
  <section class="features">
    <h2>我们的特色功能</h2>
    <div class="feature-list">
      <div class="feature-item">
        <h3>智能考勤</h3>
        <p>通过人脸识别技术实现无感考勤。</p>
      </div>
      <div class="feature-item">
        <h3>在线学习</h3>
        <p>提供丰富的在线课程和学习资源。</p>
      </div>
      <div class="feature-item">
        <h3>校园安全</h3>
        <p>实时监控校园安全，保障师生安全。</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection'
}
</script>

<style scoped>
.features {
  padding: 2rem;
  background-color: #f8f9fa;
  text-align: center;
}

.feature-list {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.feature-item {
  width: 30%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  color: #007bff;
}
</style>