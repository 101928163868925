<template>
  <div>
    <HeroSection />
    <FeaturesSection />
    <TestimonialsSection />
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import FeaturesSection from '@/components/FeaturesSection.vue';
import TestimonialsSection from '@/components/TestimonialsSection.vue';

export default {
  name: 'Home',
  components: {
    HeroSection,
    FeaturesSection,
    TestimonialsSection
  }
}
</script>