<template>
  <div>
    <h1>关于我们</h1>
    <p>智慧校园是一家致力于打造智能化校园环境的科技公司。</p>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>
h1 {
  color: #007bff;
}
</style>