<template>
  <footer>
    <p>&copy; 2023 智慧校园. 保留所有权利.</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  text-align: center;
  padding: 1rem;
  background-color: #f8f9fa;
  margin-top: 2rem;
}
</style>