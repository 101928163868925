<template>
  <section class="hero">
    <h1>欢迎来到智慧校园</h1>
    <p>我们致力于打造智能化、数字化的校园环境。</p>
    <router-link to="/services" class="btn">了解更多</router-link>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style scoped>
.hero {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #007bff;
  color: white;
}

.hero h1 {
  font-size: 2.5rem;
}

.hero p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.hero .btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #007bff;
  text-decoration: none;
  border-radius: 5px;
}

.hero .btn:hover {
  background-color: #f8f9fa;
}
</style>