<template>
  <header>
    <div class="logo">
      <img src="../assets/logo.png" alt="智慧校园 Logo">
    </div>
    <nav>
      <router-link to="/">首页</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/services">服务</router-link>
      <router-link to="/contact">联系我们</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
}

.logo img {
  height: 100px;
}

nav a {
  margin: 0 1rem;
  text-decoration: none;
  color: #333;
}

nav a:hover {
  color: #007bff;
}
</style>