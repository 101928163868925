<template>
  <section class="testimonials">
    <h2>用户评价</h2>
    <div class="testimonial-list">
      <div class="testimonial-item">
        <p>“智慧校园让我们的校园管理更加高效！”</p>
        <span>—— 张校长</span>
      </div>
      <div class="testimonial-item">
        <p>“在线学习平台非常实用，资源丰富。”</p>
        <span>—— 李老师</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TestimonialsSection'
}
</script>

<style scoped>
.testimonials {
  padding: 2rem;
  background-color: #e9ecef;
  text-align: center;
}

.testimonial-list {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.testimonial-item {
  width: 45%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-item p {
  font-style: italic;
  color: #555;
}

.testimonial-item span {
  display: block;
  margin-top: 0.5rem;
  font-weight: bold;
  color: #007bff;
}
</style>