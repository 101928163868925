<template>
  <div>
    <h1>联系我们</h1>
    <p>如有任何问题，请通过以下方式联系我们：</p>
    <ul>
      <li>电话：123-456-7890</li>
      <li>邮箱：info@smartcampus.com</li>
      <li>地址：智慧校园科技大厦</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
h1 {
  color: #007bff;
}
</style>