<template>
  <div>
    <h1>我们的服务</h1>
    <p>智慧校园提供以下服务：</p>
    <ul>
      <li>校园管理系统</li>
      <li>智能考勤系统</li>
      <li>在线学习平台</li>
      <li>校园安全监控</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Services'
}
</script>

<style scoped>
h1 {
  color: #007bff;
}
</style>